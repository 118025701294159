



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataCell from "./DataCell.vue";
import { CellData, DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataCell,
  },
})
export default class FleetOverviewTable extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Array, default: () => [] }) links!: string[] | null;

  sortBy = "";
  sortDesc = false;
  pageLimit = 10;

  //  @Getters
  get isLessThanLimit(): boolean {
    return this.rows.length <= this.pageLimit;
  }

  //  @Methods
  cellData(row: DataQualityRow, header: DataQualityHeader, index: number): CellData {
    return {
      column: header.value,
      columnName: header.text,
      type: header.type,
      value: row[header.value].value,
      link: this.links ? this.links[index] : null,
    };
  }

  headerClasses(header: any): string[] {
    return [
      header.class,
      {
        sortable: header.sortable,
        "no-sort": this.sortBy !== header.value,
      },
    ];
  }

  headerStyles(header: any): any {
    return {
      width: header.width ? header.width : "auto",
      "min-width": header.width ? header.width : "auto",
    };
  }

  sort(value: any): void {
    if (this.sortBy === value) {
      if (this.sortDesc) {
        this.sortBy = "";
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }
}
