import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
export const headers: DataQualityHeader[] = [
  {
    text: "Vessel",
    type: "string",
    value: "vesselName",
    width: "260px",
  },
  {
    text: "Data Availability",
    type: "dataIndicator",
    value: "dataAvailability",
    class: "text-center",
    tooltip: "Indicates whether critical data tags are available",
  },
  {
    text: "Data Outliers",
    type: "dataIndicator",
    value: "dataOutliers",
    class: "text-center",
    tooltip: "Indicates the amount of outliers for critical data tags",
  },
  {
    text: "Speed Log",
    type: "dataIndicator",
    value: "speedLog",
    class: "text-center",
    tooltip: "Indicates quality of speed log data using drift algorithm that compares it to derived STW",
  },
];
