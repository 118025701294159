

























import { Vue, Component } from "vue-property-decorator";
import store from "@/store";
//  components
import FleetOverviewTable from "@/components/dataQuality/FleetOverviewTable/index.vue";
//  types
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { headers } from "./VDataTableHeaders/FleetOverview";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    FleetOverviewTable,
  },
})
export default class FleetOverview extends Vue {
  searchQuery = "";
  links: (string | null)[] = [null, "/DataQuality/DataAvailability", "/DataQuality/DataOutliers", null];

  headers: DataQualityHeader[] = headers;

  //  @Getters
  get fleetOverview(): any {
    var fleetOverview = DataQuality.fleetOverview.map(item => {
      const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
      return {
        vesselName: {
          value: vessel ? vessel.name : "Unknown Vessel",
        },
        overallStatus: {
          value: item.overallStatus,
        },
        dataAvailability: {
          value: item.dataAvailability,
        },
        dataOutliers: {
          value: item.dataOutliers,
        },
        speedLog: {
          value: 0,
        },
      };
    });
    return Boolean(this.searchQuery !== null && this.searchQuery.trim()) ? fleetOverview.filter(item => item.vesselName.value.toLowerCase().includes(this.searchQuery.toLowerCase())) : fleetOverview;
  }

  get tableLoading(): boolean {
    return DataQuality.loadingState;
  }

  //  @Hooks
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshFleetOverview()]);
  }
}
